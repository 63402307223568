import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form as CommonForm, Button, Spinner } from 'Common';
import { TEXT } from 'locales/translations';
import { InsuranceContainerContext } from 'components/Container/InsuranceContainer/InsuranceContainerContext';
import { getCurrentUser, isGuest, isRegisterHealthInsuranceLoading, isValidateInsuranceNumberFailed } from 'redux/query';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isBirthday, isValidEmail, isValidInsuranceNumber, validOptionalInsuranceNumbersLength, isValidPostalCode } from 'utils/validators';
import { dateStringToDate, toIsoDate } from 'utils';
import { registerUserWithInsuranceNumberRequest, setInsuranceProvider } from 'redux/actions';

export const Form = ({ children, withoutInsuranceNumber = false, disabled = false, onSubmit, optionalFields = {}, defaultValues = {} }) => {
  const dispatch = useDispatch();

  const user = useSelector(getCurrentUser);
  const isGuestUser = useSelector(isGuest);
  const isInsuranceNumberFailed = useSelector(isValidateInsuranceNumberFailed);

  const [formState, setFormState] = useState({
    name: isGuestUser ? '' : user.firstName,
    email: isGuestUser ? '' : user.email,
    healthCheck: false,
    termsCheck: false,
    ...defaultValues
  });

  const {
    register: _register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: isGuestUser ? '' : user.firstName,
      email: isGuestUser ? '' : user.email,
      healthCheck: false,
      termsCheck: false,
      ...defaultValues
    },
    mode: 'onBlur'
  });

  const { b2bClientKey } = useContext(InsuranceContainerContext);
  const isLoading = useSelector(isRegisterHealthInsuranceLoading);
  const isDisabled = useMemo(() => {
    for (const key in formState) {
      const value = formState[key];
      if (key === 'insuranceNumber' && withoutInsuranceNumber && !value) {
        continue;
      }
      if (errors?.[key] || (!value && !optionalFields[key])) {
        return true;
      }
    }
    return false;
  }, [formState, errors, optionalFields]);

  const handleOnSubmit = async (evt) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    if (onSubmit) {
      return await handleSubmit(onSubmit)(evt);
    }
    await handleSubmit(registerUser)(evt);
  };

  useEffect(() => {
    if (isInsuranceNumberFailed) {
      setError('insuranceNumber', {
        type: 'manual',
        message: TEXT.formsGeneral.insuranceNumberAlreadyExists
      });
    }
  }, [isInsuranceNumberFailed, setError]);

  const register = useCallback(
    (name, rules = {}) => {
      const _additionalAttributes = {};
      const _rules = {
        ...rules,
        disabled,
        onChange: (evt) => {
          if (evt && evt.target) {
            switch (true) {
              case evt.target.type === 'checkbox': {
                setFormState((prevState) => ({ ...prevState, [name]: evt.target.checked }));
                break;
              }
              case 'value' in evt.target: {
                setFormState((prevState) => ({ ...prevState, [name]: evt.target.value }));
                break;
              }
            }
          }
          rules.onChange && rules.onChange(evt);
          clearErrors(name);
        }
      };

      switch (name) {
        case 'name': {
          _rules.required = TEXT.formsGeneral.requiredName;
          break;
        }
        case 'email': {
          _rules.required = TEXT.formsGeneral.requiredEmail;
          _rules.validate = {
            validMail: (value) => isValidEmail(value) || TEXT.formsGeneral.validEmail
          };
          break;
        }
        case 'insuranceNumber': {
          _rules.required = TEXT.formsGeneral.validLengthNum;
          let validationRuleFn = isValidInsuranceNumber;

          if (withoutInsuranceNumber) {
            validationRuleFn = validOptionalInsuranceNumbersLength;
            _rules.required = false;
          }

          _rules.validate = {
            validInsuranceNumber: (value) => validationRuleFn(value) || TEXT.formsGeneral.validInsuranceNumber
          };

          break;
        }
        case 'dateOfBirth': {
          _rules.required = TEXT.formsGeneral.requiredDateOfBirth;
          _rules.validate = {
            invalidDate: (value) => dateStringToDate(value).toString() !== 'Invalid Date' || TEXT.formsGeneral.validDateOfBirth,
            invalidBirthDate: (value) => isBirthday(value) || TEXT.formsGeneral.validDateOfBirth
          };
          break;
        }
        case 'postalCode': {
          _rules.required = TEXT.formsGeneral.requiredPostalCode;
          _rules.validate = {
            validPostcode: (value) => isValidPostalCode(value) || TEXT.formsGeneral.validPostalCode
          };
          break;
        }
        case 'healthCheck': {
          _rules.required = TEXT.formsGeneral.requiredHealthCheck;
          break;
        }
        case 'termsCheck': {
          _rules.required = TEXT.formsGeneral.requiredTermsCheck;
          break;
        }
        case 'postalCodeCheck': {
          _rules.required = TEXT.formsGeneral.validPostalCodeCheck;
          break;
        }
        case 'precondition': {
          _rules.required = TEXT.formsGeneral.requiredPrecondition;
          break;
        }
        case 'gender': {
          if (!!rules.required) {
            _rules.required = typeof rules.required === 'string' ? rules.required : TEXT.formsGeneral.requiredGender;
          }
          _additionalAttributes.options = [
            { value: 'FEMALE', label: `${TEXT.genderOptions.female}` },
            { value: 'MALE', label: `${TEXT.genderOptions.male}` },
            { value: 'DIVERS', label: `${TEXT.genderOptions.divers}` }
          ];
          break;
        }
        case 'age': {
          if (!!rules.required) {
            _rules.required = typeof rules.required === 'string' ? rules.required : TEXT.formsGeneral.requiredAge;
          }
          _rules.max =
            typeof rules.max === 'boolean'
              ? {
                  value: 120,
                  message: TEXT.formsGeneral.ageToHigh
                }
              : rules.max;
          _rules.min =
            typeof rules.min === 'boolean'
              ? {
                  value: 14,
                  message: TEXT.formsGeneral.ageToLow
                }
              : rules.max;

          break;
        }
      }

      return {
        ..._register(name, _rules),
        ..._additionalAttributes,
        key: `form-input-field-${name}`,
        feedbackMessage: errors[name]?.message,
        valid: !!errors[name] ? false : undefined,
        dataCyName: `${name}-input`
      };
    },
    [errors, disabled]
  );

  const registerUser = () => {
    const { name = '', email = '', insuranceNumber = '', ...userMetaData } = formState;
    const _userMetaData = { ...userMetaData };
    if (formState.dateOfBirth) {
      _userMetaData.dateOfBirth = toIsoDate(formState.dateOfBirth);
    }
    Object.keys(formState).forEach((key) => {
      const value = formState[key];
      if (!value && defaultValues[key]) {
        formState[key] = defaultValues[key];
      }
    });

    dispatch(setInsuranceProvider({ insuranceProvider: b2bClientKey }));
    dispatch(
      registerUserWithInsuranceNumberRequest({
        user: { name, email, insuranceNumber },
        // Override userMetaData transformations
        userMetaData: _userMetaData,
        sendDeepLinkOTP: false,
        optionalInsuranceNumber: withoutInsuranceNumber
      })
    );
  };

  return (
    <div className="insurance-form-wrapper" data-cy-name="landingpage-register-form">
      <CommonForm className="form" onSubmit={handleOnSubmit}>
        {children({ register, values: formState, errors })}
        <Button type="submit" color="green" dataCyName="submit-register-button" disabled={isDisabled}>
          {isLoading ? <Spinner /> : TEXT.registration.register_btn}
        </Button>
      </CommonForm>
    </div>
  );
};
