import styled from 'styled-components';
import { asRem } from 'utils';
import { breakpoints, colors } from 'components/Theme';
export default styled.div`
  display: inline-block;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .insurance-form-wrapper {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }

  .header {
    width: 100%;
    text-align: center;

    .mime-elements-paragraph {
      padding-left: ${asRem(24)};
      padding-right: ${asRem(24)};
    }
  }

  .mime-elements-paragraph {
    &.extra-small {
      font-size: ${asRem(14)};
      line-height: ${asRem(18)};
    }
  }

  .form {
    width: 100%;
    margin-bottom: ${asRem(-24)};
    padding: 0 ${asRem(24)};

    @media (min-width: ${breakpoints.md}) {
      width: 68%;
      margin-bottom: ${asRem(64)};
      padding: ${asRem(21)} ${asRem(10)} 0;
    }
  }

  .mime-elements-headline-2 {
    text-align: center;
    color: ${colors.green.c500};
    margin: ${asRem(32)} 0 ${asRem(13)};
  }

  .info-text {
    width: auto;
    text-align: center;
    color: ${colors.black.c500};
    margin: 0 ${asRem(16)} ${asRem(32)};

    @media (min-width: ${breakpoints.md}) {
      margin: 0 ${asRem(48)} ${asRem(16)};
    }
  }

  .mime-elements-button {
    width: 100%;
    margin-bottom: ${asRem(32)};
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto ${asRem(48)};
    max-width: ${asRem(780)};
    background-color: ${colors.white.c500};
    color: ${colors.blueGrey.c500};
    border-radius: ${asRem(10)};
    overflow: hidden;
    padding: ${asRem(48)} ${asRem(64)};
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  }
`;
