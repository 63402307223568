export const removeOpenRequest = (action, state) => {
  if (Array.isArray(action)) {
    let openRequests = [];
    action.forEach((a) => {
      openRequests = removeOpenRequest(a, { openRequests });
    });
    return openRequests;
  }

  const openRequests = [...new Set(state.openRequests)];
  let actionType = action.type;
  if (!!actionType.match('SUCCESS')) {
    actionType = action.type.replace('SUCCESS', 'REQUEST');
  } else if (!!actionType.match('FAILURE')) {
    actionType = action.type.replace('FAILURE', 'REQUEST');
  }

  let index = openRequests.indexOf(actionType);
  while (index > -1) {
    openRequests.splice(index, 1);
    index = openRequests.indexOf(actionType);
  }
  return [...new Set(openRequests)];
};

export const removeFailedRequest = (action, state) => {
  if (Array.isArray(action)) {
    let failedRequests = [];
    action.forEach((a) => {
      failedRequests = removeOpenRequest(a, { failedRequests });
    });
    return failedRequests;
  }

  const failedRequests = [...state.failedRequests];
  let actionType = action.type;
  if (!!actionType.match('SUCCESS')) {
    actionType = action.type.replace('SUCCESS', 'FAILURE');
  } else if (!!actionType.match('REQUEST')) {
    actionType = action.type.replace('REQUEST', 'FAILURE');
  }

  let index = failedRequests.indexOf(actionType);
  while (index > -1) {
    failedRequests.splice(index, 1);
    index = failedRequests.indexOf(actionType);
  }
  return [...new Set(failedRequests)];
};
