import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCompanies } from 'redux/query';
import { getInsuranceCompaniesRequest } from 'redux/actions';

export const SelectHealthInsuranceCompany = ({ onChange = () => {} }) => {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    dispatch(getInsuranceCompaniesRequest());
  }, []);

  const insuranceCompanies = useSelector(getInsuranceCompanies);

  const handleOnChange = (company) => {
    if (!company?.[0]) {
      onChange(null);
      setSelectedCompany(null);
      return;
    }
    const refunds = (company?.[0]?.reductionRate * 75.0) / 100;
    company = { ...company[0], refunds };
    setSelectedCompany(company);
    onChange(company);
  };

  return (
    <Typeahead
      maxResults={400}
      id="basic-typeahead-single"
      labelKey="name"
      className="dropdown-toggle"
      onChange={handleOnChange}
      options={insuranceCompanies}
      placeholder="Wähle deine Krankenkasse"
      selected={selectedCompany ? [selectedCompany] : []}
      allowNew={false}
      paginationText="Weitere anzeigen"
      renderMenu={(results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
        const items = results.map((result, index) => (
          <MenuItem
            key={index}
            option={result}
            position={index}
            className={
              result.cooperationPartner && results[index + 1] && !results[index + 1].cooperationPartner ? 'cooperation-partner' : ''
            }
          >
            {result.name}
          </MenuItem>
        ));
        return (
          <Menu {...menuProps} emptyLabel="Krankenkasse nicht gefunden">
            {items}
          </Menu>
        );
      }}
    />
  );
};
