import { TEXT } from 'locales/translations';
import { ROUTES } from './routes';

const getTextForCompany = (companyKey) => ({
  headline: TEXT.healthCheckout.selected[companyKey].headline,
  info: TEXT.healthCheckout.selected[companyKey].info,
  checkoutBox: {
    aboTitle: TEXT.healthCheckout.selected[companyKey].box.aboTitle,
    infoText: TEXT.healthCheckout.selected[companyKey].box.infoText,
    asteriskText: TEXT.healthCheckout.selected[companyKey].box.asteriskText
  }
});

export const healthInsuranceCooperationCompanies = [
  {
    route: ROUTES.dak,
    ...getTextForCompany('dak')
  },
  {
    route: ROUTES.viactiv,
    ...getTextForCompany('viactiv')
  },
  {
    route: ROUTES.mkk,
    ...getTextForCompany('mkk')
  },
  {
    route: ROUTES.bigDirect,
    ...getTextForCompany('bigDirect')
  },
  {
    route: ROUTES.ikkClassic,
    ...getTextForCompany('ikkClassic')
  },
  {
    route: ROUTES.hkk,
    ...getTextForCompany('hkk')
  },
  {
    route: ROUTES.vividaBkk,
    ...getTextForCompany('vividaBkk')
  },
  {
    route: ROUTES.salusBkk,
    ...getTextForCompany('salusBkk')
  },
  {
    route: ROUTES.tk,
    ...getTextForCompany('tk')
  }
];
